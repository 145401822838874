import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import CustomCard from "../CustomCard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function RenderBlogList({ list = [] }) {
  const classes = useStyles();

  const renderList = (list) =>
    list.map((data) => (
      <Grid key={data.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
        <CustomCard data={data} />
      </Grid>
    ));

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {list.length > 0 ? (
          renderList(list)
        ) : (
          <CircularProgress color="secondary" />
        )}
      </Grid>
    </div>
  );
}
