import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Switch, Route } from "react-router-dom";
import DashboardMenu from "./DashboardMenu";

// Dashboard Pages
import Bugs from './DashboardPages/Bugs'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    padding: 0,
  },
  aside: {
    background: "#332d2d",
  },
  main: {
    background: "#d64855",
  },
}));

export default function Dashboard({ match }) {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container spacing={0}>
      <Grid className={classes.aside} item xl={2} xs={2}>
        <DashboardMenu match={match} />
      </Grid>
      <Grid className={classes.main} item xl={10} xs={10}>
        <Switch>
          <Route
            path={`${match.path}`}
            exact
            component={() => <h1>Dashboard</h1>}
          />
          <Route
            path={`${match.path}/myvideos`}
            exact
            component={() => <h1>videos</h1>}
          />
          <Route
            path={`${match.path}/mylessons`}
            exact
            component={() => <h1>lessons</h1>}
          />
          <Route
            path={`${match.path}/myblog`}
            exact
            component={() => <h1>blog</h1>}
          />
          <Route
            path={`${match.path}/myprofile`}
            exact
            component={() => <h1>profile</h1>}
          />
          <Route path={`${match.path}/bugs`} exact component={Bugs} />
        </Switch>
      </Grid>
    </Grid>
  );
}
