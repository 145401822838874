import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinkList from "../components/LinkList";
import { useGetDataHook } from "../hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100%",
    padding: "1rem",
    background: theme.palette.custom.highlightColor,
  },
}));

const Links = () => {
  const classes = useStyles();
  const url = `https://scrapiapi.herokuapp.com/links`;

  const { status, data } = useGetDataHook(url);

  console.log(status, data, "AFTER CALLING HOOK");

  return (
    <div className={classes.root}>
      <LinkList list={data} />
    </div>
  );
};

export default Links;
