import { createMuiTheme } from "@material-ui/core/styles";

const codingOrange = "#faa700";
const codingGray = "#eff2f4";
const codingDarkGrey = "#2c3439";
const codingLightGrey = "#332d2d";
const codingDargGreyText = "#b9bfc3";
const codingBlack = "#232322";
const codingPink = "#d64956";
const highlightColor = "#d64855";

export const theme = createMuiTheme({
  palette: {
    custom: {
      highlightColor: highlightColor,
    },

    common: {
      codingBlack,
      codingGray,
      codingDarkGrey,
      codingDargGreyText,
      codingLightGrey,
      codingOrange,
      codingPink,
      codingBackground: "#eff2f4",
      codingBackgroundDarker: "#dfe4e8",
      codingWhite: "#ffffff",
    },

    sideMenu: {
      // background: '#191616',
      background: "#1d1a1a",
    },

    primary: {
      main: codingPink,
    },

    secondary: {
      main: codingGray,
    },
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
  },
  // overrides: {
  //   MuiButton: {
  //     root: {
  //       fontSize: '1rem',
  //     },
  //     containedPrimary: {
  //       background: 'white',
  //     },
  //     containedSecondary: {
  //       color: 'blue'
  //     }
  //   },
  // },
});
