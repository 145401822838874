import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { useLocation, useHistory, Link } from "react-router-dom"; // Link, , useHistory

// icons
import ComputerOutlinedIcon from "@material-ui/icons/ComputerOutlined";
import OndemandVideoOutlinedIcon from "@material-ui/icons/OndemandVideoOutlined";
import ChromeReaderModeOutlinedIcon from "@material-ui/icons/ChromeReaderModeOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import BugReportOutlinedIcon from "@material-ui/icons/BugReportOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.codingLightGrey,
  },
}));

export default function DashboardMenuMobile() {
  let location = useLocation();
  let history = useHistory();

  const classes = useStyles();
  const [value, setValue] = React.useState();
  const [selectedIndex, setSelectedIndex] = React.useState("");

  React.useEffect(() => {
    setSelectedIndex(location.pathname);
    // history.push(value);
    console.log(location.pathname, "test");
  }, [location, selectedIndex, history]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <BottomNavigation
      value={selectedIndex || value}
      onChange={handleChange}
      className={classes.root}
    >
      <BottomNavigationAction
        component={Link}
        to="/dashboard/myvideos"
        label="Videos"
        value="/dashboard/myvideos"
        icon={
          <OndemandVideoOutlinedIcon
            color={
              selectedIndex === "/dashboard/myvideos" ? "primary" : "secondary"
            }
          />
        }
      />
      <BottomNavigationAction
        component={Link}
        to="/dashboard/mylessons"
        label="Lessons"
        value="/dashboard/mylessons"
        icon={
          <ComputerOutlinedIcon
            color={
              selectedIndex === "/dashboard/mylessons" ? "primary" : "secondary"
            }
          />
        }
      />
      <BottomNavigationAction
        component={Link}
        to="/dashboard/myblog"
        label="Blog"
        value="/dashboard/myblog"
        icon={
          <ChromeReaderModeOutlinedIcon
            color={
              selectedIndex === "/dashboard/myblog" ? "primary" : "secondary"
            }
          />
        }
      />
      <BottomNavigationAction
        component={Link}
        to="/dashboard/myprofile"
        label="Profile"
        value="/dashboard/myprofile"
        icon={
          <AccountCircleOutlinedIcon
            color={
              selectedIndex === "/dashboard/myprofile" ? "primary" : "secondary"
            }
          />
        }
      />
      <BottomNavigationAction
        component={Link}
        to="/dashboard/bugs"
        label="Bugs"
        value="/dashboard/bugs"
        icon={
          <BugReportOutlinedIcon
            color={
              selectedIndex === "/dashboard/bugs" ? "primary" : "secondary"
            }
          />
        }
      />
    </BottomNavigation>
  );
}
