import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "calc(100vh)",
    padding: 0,
    background: "orange",
    overflowY: "auto",

    [theme.breakpoints.up("sm")]: {
      paddingTop: "64px",
    },
  },
}));
