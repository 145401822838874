import React from "react";
import Root from "./layout/Root";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./theme";

import { createStore } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "./redux/reducers";

const store = createStore(rootReducer, composeWithDevTools());

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <Root />
      </Router>
    </Provider>
  </ThemeProvider>
);
export default App;
