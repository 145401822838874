import React from "react";

export default function useFormValidation(
  initialState,
  validateForm,
  callbackFunction,
  dispatch,
  history
) {
  const [values, setValues] = React.useState(initialState);
  const [errors, setErrors] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (isSubmitting) {
      const noErrors = Object.keys(errors).length === 0;
      if (noErrors) {
        callbackFunction(values, setIsSubmitting, dispatch, history);
      } else {
        setIsSubmitting(false);
      }
    }
  }, [errors, isSubmitting, values, callbackFunction, dispatch, history]);

  function handleFieldChange(e) {
    e.persist();
    console.log(e.target.name + ":" + e.target.value);
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  const handleBlur = () => {
    const validationErrors = validateForm(values);
    setErrors(validationErrors);
  };

  const handleFormSubmit = (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    const validationErrors = validateForm(values);
    setErrors(validationErrors);
  };

  return {
    handleFieldChange,
    handleFormSubmit,
    handleBlur,
    setValues,
    values,
    errors,
    isSubmitting,
  };
}
