import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";

export const listItemTextStyles = {
  color: theme.palette.common.codingDargGreyText,
  fontWeight: "bold",
};

const drawerWidth = 100;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,

  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },

  appBarLogo: {
    display: "flex",
    alignItems: "center",
  },

  appBarLogoText: {
    fontWeight: "bold",
    fontSize: "1.6rem",
    color: "#4e4545",
  },

  appBarContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  menuListItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  menuListItemContainer: {
    minWidth: 0,
  },

  menuListItemIcon: {
    fontSize: "3.4rem",
  },

  drawerHeaderIcon: {
    color: theme.palette.common.codingDargGreyText,
  },

  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.sideMenu.background,
  },
}));
