import React from "react";

export default function RenderChildList({ data, children, layout }) {
  if (!data && !children) return <p>No data or children provided</p>;
  if (data.length === 0) return <p>No data found</p>;

  const items = data.map((item) => (
    <div key="item.id">{React.cloneElement(children, { item })}</div>
  ))

  if (layout) return layout(items) 
  else return items;
}
