import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
// import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FaceIcon from "@material-ui/icons/Face";
import SchoolIcon from "@material-ui/icons/School";
import LinkIcon from '@material-ui/icons/Link';
import MainContent from "../../MainRoutes";
import { useStyles, listItemTextStyles } from "./styles";
import { logOutUser } from "../../redux/actions";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// ICONS
// import ExploreIcon from "@material-ui/icons/Explore";
// import WhatshotIcon from "@material-ui/icons/Whatshot";
// import AppsIcon from "@material-ui/icons/Apps";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
// import SchoolIcon from "@material-ui/icons/School";
import BookIcon from "@material-ui/icons/Book";

export default function Root(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState("");
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  let location = useLocation();
  let history = useHistory();

  React.useEffect(() => {
    setSelectedIndex(location.pathname);
  }, [location]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setMobileOpen(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <div className={classes.drawerHeader}></div>
      <List>
        {/* <ListItem
            component={Link}
            to="explore"
            onClick={(event) => handleListItemClick(event, "/explore")}
            className={classes.menuListItem}
          >
            <ListItemIcon className={classes.menuListItemContainer}>
              <ExploreIcon
                className={classes.menuListItemIcon}
                color={selectedIndex === "/explore" ? "primary" : "secondary"}
              />
            </ListItemIcon>
            <ListItemText
              primary="Explore"
              primaryTypographyProps={{ style: listItemTextStyles }}
            />
          </ListItem> */}

        {/* <ListItem
          component={Link}
          to="/"
          onClick={(event) => handleListItemClick(event, "/")}
          className={classes.menuListItem}
        >
          <ListItemIcon className={classes.menuListItemContainer}>
            <AppsIcon
              className={classes.menuListItemIcon}
              color={selectedIndex === "/" ? "primary" : "secondary"}
            />
          </ListItemIcon>
          <ListItemText
            primary="Home"
            primaryTypographyProps={{ style: listItemTextStyles }}
          />
        </ListItem> */}

        <ListItem
          component={Link}
          to="/"
          onClick={(event) => handleListItemClick(event, "/")}
          className={classes.menuListItem}
        >
          <ListItemIcon className={classes.menuListItemContainer}>
            <VideoLibraryIcon
              className={classes.menuListItemIcon}
              color={selectedIndex === "/" ? "primary" : "secondary"}
            />
          </ListItemIcon>
          <ListItemText
            primary="Videos"
            primaryTypographyProps={{ style: listItemTextStyles }}
          />
        </ListItem>

        <ListItem
          component={Link}
          to="/links"
          onClick={(event) => handleListItemClick(event, "/links")}
          className={classes.menuListItem}
        >
          <ListItemIcon className={classes.menuListItemContainer}>
            <LinkIcon
              className={classes.menuListItemIcon}
              color={selectedIndex === "/links" ? "primary" : "secondary"}
            />
          </ListItemIcon>
          <ListItemText
            primary="Links"
            primaryTypographyProps={{ style: listItemTextStyles }}
          />
        </ListItem>

        {/* <ListItem
                component={Link}
                to="trending"
                onClick={(event) => handleListItemClick(event, "/trending")}
                className={classes.menuListItem}
                classes={{ selected: classes.menuListItemSelected }}
              >
                <ListItemIcon className={classes.menuListItemContainer}>
                  <WhatshotIcon
                    className={classes.menuListItemIcon}
                    color={
                      selectedIndex === "/trending" ? "primary" : "secondary"
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Trending"
                  primaryTypographyProps={{ style: listItemTextStyles }}
                />
              </ListItem> */}

        <ListItem
          component={Link}
          to="/blog"
          onClick={(event) => handleListItemClick(event, "/blog")}
          className={classes.menuListItem}
        >
          <ListItemIcon className={classes.menuListItemContainer}>
            <BookIcon
              className={classes.menuListItemIcon}
              color={selectedIndex === "/blog" ? "primary" : "secondary"}
            />
          </ListItemIcon>
          <ListItemText
            primary="Blog"
            primaryTypographyProps={{ style: listItemTextStyles }}
          />
        </ListItem>

        {currentUser && (
          <>
            <ListItem
              component={Link}
              to="/lessons"
              onClick={(event) => handleListItemClick(event, "/lessons")}
              className={classes.menuListItem}
              color={selectedIndex === "/lessons" ? "primary" : "secondary"}
            >
              <ListItemIcon className={classes.menuListItemContainer}>
                <SchoolIcon
                  className={classes.menuListItemIcon}
                  color={selectedIndex === "/lessons" ? "primary" : "secondary"}
                />
              </ListItemIcon>
              <ListItemText
                primary="Lessons"
                primaryTypographyProps={{ style: listItemTextStyles }}
              />
            </ListItem>
            <ListItem
              component={Link}
              to="/dashboard/"
              onClick={(event) => handleListItemClick(event, "/dashboard")}
              className={classes.menuListItem}
            >
              <ListItemIcon className={classes.menuListItemContainer}>
                <FaceIcon
                  className={classes.menuListItemIcon}
                  color={
                    selectedIndex.slice(0, 10) === "/dashboard"
                      ? "primary"
                      : "secondary"
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                primaryTypographyProps={{ style: listItemTextStyles }}
              />
            </ListItem>
          </>
        )}

        <Hidden smUp implementation="css">
          {!currentUser && (
            <ListItem
              component={Link}
              to="signin"
              onClick={(event) => handleListItemClick(event, "/sigin")}
              className={classes.menuListItem}
            >
              <ListItemIcon className={classes.menuListItemContainer}>
                <FaceIcon
                  className={classes.menuListItemIcon}
                  color={selectedIndex === "/signin" ? "primary" : "secondary"}
                />
              </ListItemIcon>
              <ListItemText
                primary="Sign In"
                primaryTypographyProps={{ style: listItemTextStyles }}
              />
            </ListItem>
          )}
        </Hidden>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} color="secondary">
        <Toolbar className={classes.appBarContainer}>
          <div className={classes.appBarLogo}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Link style={{ textDecoration: "none", color: "white" }} to="/">
              <Typography
                className={classes.appBarLogoText}
                variant="h6"
                noWrap
              >
                courseshare.io
              </Typography>{" "}
            </Link>
          </div>
          <Hidden xsDown implementation="css">
            <div className={classes.appBarContent}>
              {currentUser ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch(logOutUser(history))}
                  disableElevation
                >
                  Sign Out
                </Button>
              ) : (
                <Button
                  component={Link}
                  to="/signin"
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Sign In
                </Button>
              )}
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            className={classes.drawer}
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.drawerHeader} />
        <MainContent />
      </main>
    </div>
  );
}

/*

 <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem className={classes.menuListItem} button key={text}>
              <ListItemIcon className={classes.menuListItemIcon}>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
        <Divider />


*/
