import axios from "axios";
import { setUser } from "../redux/actions";

// Request API.
// Add your own code here to customize or restrict how the public can register new users.
// https://courseshareapi.herokuapp.com
export function handleRegistration(values, setIsSubmitting, dispatch, history) {
  axios
    .post("http://localhost:1337/auth/local/register", values)
    .then((response) => {
      // Handle success.
      const user = response.data.user;
      const token = response.data.jwt;

      // setter
      const tempData = { token, user };
      sessionStorage.setItem("user", JSON.stringify(tempData));

      // dispatch action
      dispatch(setUser(user, token));

      history.push("/dashboard");
    })
    .catch((error) => {
      // Handle error.
      console.log("An error occurred:", error.response);
      setIsSubmitting(false);
    });
}

export function handleSignIn(values, setIsSubmitting, dispatch, history) {
  const renamedKeys = {
    identifier: values.username,
    password: values.password,
  };

  axios
    .post("http://localhost:1337/auth/local", renamedKeys)
    .then((response) => {
      // Handle success.
      const user = response.data.user;
      const token = response.data.jwt;

      // setter
      const tempData = { token, user };
      sessionStorage.setItem("user", JSON.stringify(tempData));

      // dispatch action
      dispatch(setUser(user, token));

      history.push("/dashboard");
    })
    .catch((error) => {
      // Handle error.
      console.log("An error occurred:", error.response);
      setIsSubmitting(false);
    });
}
