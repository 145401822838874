import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useGetDataHook } from "../hooks";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100%",
    padding: "1rem",
    background: theme.palette.custom.highlightColor,
  },
}));

const SinglePost = () => {
  const { id } = useParams();
  const classes = useStyles();
  const url = `https://scrapiapi.herokuapp.com/posts/${id}`;
  const filter = "?Private_eq=false";

  const { status, data } = useGetDataHook(url, filter);

  console.log(status, data, "AFTER CALLING HOOK");

  return (
    <div className={classes.root}>
      <h1>Features coming soon {id}</h1>
    </div>
  );
};

export default SinglePost;
