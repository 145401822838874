import React from "react";
import Button from "@material-ui/core/Button";
import { useStyles } from "./styles";

import styled from "styled-components";

const ShowTagsStyled = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    text-align: center;
`;

export default function ShowCategory({ category }) {
  const classes = useStyles();

  return (
    <ShowTagsStyled>
        <Button
          className={classes.category}
          to="videos"
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => console.log(category.id)}
        >
          {category.Name}
        </Button>
    </ShowTagsStyled>
  );
}
