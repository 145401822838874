import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ShowCategory from '../ShowCategory';

import { Link } from "react-router-dom";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    position: 'relative',
    overflow: 'visible',
    borderBottomRightRadius: '15px',
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function CardBlogView({ data }) {
  const classes = useStyles();
  const baseUrl = "https://scrapiapi.herokuapp.com";
  console.log(data.category, 'stuff')
  return (

    <Card className={classes.root}>
      <ShowCategory category={data.category} />
      <Link to={`/blog/${data.id}`}>
      <CardMedia
        className={classes.media}
        image={baseUrl + data.FeaturedImage.formats.medium.url}
        title={data.Title}
      />
      </Link>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            R
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={data.Title}
        subheader={format(new Date(data.TimeStamp), "MMMM do, yyyy.")}
      />
      <CardContent>
       
        <Typography variant="body2" color="textSecondary" component="p">
          {data.Content.slice(0,143) + '...'}
        </Typography>
        
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
