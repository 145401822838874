import React from "react";
import Grid from "@material-ui/core/Grid";
import { Switch, Route } from "react-router-dom";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/actions";

import SignIn from "../pages/Auth/SignIn";
import SignUp from "../pages/Auth/SignUp";
import Videos from "../pages/Videos";
import Blog from "../pages/Blog";
import SinglePosts from "../pages/SinglePost";
import RenderDashboard from "../pages/RenderDashboard";
import Links from "../pages/Links";

function MainRoutes() {
  const { currentUser } = useSelector((state) => state.user);
  let history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const localStorage = sessionStorage.getItem("user");

    if (localStorage) {
      dispatch(setUser(localStorage.user, localStorage.token));
    }
  }, [dispatch, history]);

  const classes = useStyles();

  return (
    <Grid className={classes.root} container spacing={0}>
      <Switch>
        <Route path="/" exact component={Videos} />
        <Route path="/signin" component={SignIn} />
        <Route path="/signup" component={SignUp} />
        <Route path="/links" component={Links} />
        <Route path="/blog" exact component={Blog} />
        <Route path="/blog/:id" component={SinglePosts} />
        
        <Route
          path="/lessons"
          component={() => <h1>Lesson Page coming soon</h1>}
        />

        {currentUser && <Route path="/dashboard" component={RenderDashboard} />}
        <Route component={() => <h1>Oops, you did it again!</h1>} />
      </Switch>
    </Grid>
  );
}

export default MainRoutes;
//       dispatch(setUser(localStorage.user, localStorage.token));
