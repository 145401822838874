import React from "react";
import CardMedia from "@material-ui/core/CardMedia";
import styled from "styled-components";
import { InsertTemplate } from "@styled-icons/icomoon";

const CardMediaStyled = styled(CardMedia)`
  border: none;
`;

const VideoEmbedYouTube = ({ item, playinline }) => {
  if (!InsertTemplate) return null;

  function getId(url) {
    try {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      return match && match[2].length === 11 ? match[2] : null;
    } catch (err) {
      console.log("Google Embed Error: ", err);
    }
  }

  const playInline = () => (playinline ? "?playsinline=1" : "?playsinline=0");

  return (
    <CardMediaStyled
      component="iframe"
      height="640"
      title="test"
      src={`https://www.youtube.com/embed/${getId(item.videoUrl) + playInline()}`}
    />
  );
};

export default VideoEmbedYouTube;
