import * as actionTypes from "./types";

export const setUser = (user, token) => {
  console.log(user, token);
  return {
    type: actionTypes.SET_USER,
    payload: {
      currentUser: { user, token },
    },
  };
};

export const logOutUser = (history) => {
  return {
    type: actionTypes.LOGOUT_USER,
    payload: {
      currentUser: null,
      history: history,
    },
  };
};
