import { makeStyles } from "@material-ui/core/styles";

// const imageRandom = 'https://source.unsplash.com/random';
const image2 =
  "https://images.unsplash.com/photo-1494506132334-19732cd53b32?ixlib=rb-1.2.1&auto=format&fit=crop&w=1951&q=80";

export const useStyles = makeStyles((theme) => ({
  container: {
    // background: theme.palette.common.codingBackgroundDarker,
    background: `url(${image2}) no-repeat center center/cover`,
    padding: 0,
    display: "flex",
    alignItems: "center",
    // background: '#283035',
    width: "100%",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.custom.highlightColor,
    height: "65px",
    width: "65px",
  },
  formContainer: {
    background: "#eff2f4",
    padding: "2rem",
    borderRadius: "4px",
    marginTop: 0,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 3),
  },
  linkText: {
    color: theme.palette.common.codingDarkGrey,
  },
}));
