import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useGetDataHook } from "../../../hooks";
import RenderBugsList from "../DashboardComponent/RenderBugsList";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100%",
    padding: "1rem",
    background: theme.palette.custom.highlightColor,
  },
}));

const Bugs = () => {
  const classes = useStyles();
  const url = `https://scrapiapi.herokuapp.com/bugs`;
  const filter = "?Resolved_eq=false";

  const { status, data } = useGetDataHook(url, filter);

  console.log(status, data, "AFTER CALLING HOOK");

  return <div className={classes.root}>{<RenderBugsList list={data} />}</div>;
};

export default Bugs;
