import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import { Link as RouterLink, withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useFormValidation } from "../../hooks";
import validateSignUpForm from "../../functions/validateSignupForm";
import { useStyles } from "./styled";
import { handleRegistration } from "../../api";
import { useDispatch } from "react-redux";

const INITIAL_STATE = {
  username: "",
  email: "",
  password: "",
};

function Login({ history }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    handleFieldChange,
    handleFormSubmit,
    handleBlur,
    values,
    errors,
    isSubmitting,
  } = useFormValidation(
    INITIAL_STATE,
    validateSignUpForm,
    handleRegistration,
    dispatch,
    history
  );

  return (
    <div className={classes.container}>
      <Container
        className={classes.formContainer}
        component="main"
        maxWidth="xs"
      >
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {"Sign Up"}
          </Typography>

          <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
            <TextField
              error={errors.username ? true : false}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="off"
              autoFocus
              onChange={handleFieldChange}
              value={values.username}
              helperText={errors.username && errors.username}
            />

            <TextField
              error={errors.email ? true : false}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleFieldChange}
              onBlur={handleBlur}
              value={values.email}
              helperText={errors.email && errors.email}
            />

            <TextField
              error={errors.password ? true : false}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleFieldChange}
              onBlur={handleBlur}
              value={values.password}
              helperText={errors.password && errors.password}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disableElevation
              className={classes.submit}
              size="large"
              disabled={isSubmitting}
            >
              {"Sign Up"}
            </Button>
            <Grid container>
              <Grid item>
                <Link
                  className={classes.linkText}
                  component={RouterLink}
                  to="/signin"
                  variant="body2"
                >
                  {"Have an account? Sign In!"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
}

export default withRouter(Login);
