import React from 'react'
import { LinkViewStyled } from './styles'

export default function LinkView({data}) {
    console.log(data, "from link views")
    return <LinkViewStyled>
        <div>
            <a href={data.Url} target="_blank" rel="noopener noreferrer"><h2>{data.Name}</h2></a>   
        </div>
        <div>        
            <p>{data.Description}</p>
            <a href={data.Url} target="_blank" rel="noopener noreferrer">({data.Url})</a>
        </div>
    </LinkViewStyled>
}
