import React from "react";
import { Link, useLocation } from "react-router-dom";

// material ui
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";

// icons
import ComputerOutlinedIcon from "@material-ui/icons/ComputerOutlined";
import OndemandVideoOutlinedIcon from "@material-ui/icons/OndemandVideoOutlined";
import ChromeReaderModeOutlinedIcon from "@material-ui/icons/ChromeReaderModeOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import BugReportOutlinedIcon from "@material-ui/icons/BugReportOutlined";

export const listItemTextStyles = {
  color: theme.palette.common.codingGray,
};

const useStyles = makeStyles({
  menuList: {
    margin: 0,
    padding: 0,
  },

  menuListText: {
    color: "white",
  },

  root: {
    "&$selected": {
      backgroundColor: theme.palette.common.codingPink,
      "&:hover": {
        backgroundColor: theme.palette.common.codingPink,
      },
    },
  },

  selected: {},
});

export default function DashboardMenu({ match }) {
  const classes = useStyles();
  let location = useLocation();
  const [selectedIndex, setSelectedIndex] = React.useState("");

  React.useEffect(() => {
    setSelectedIndex(location.pathname);
    console.log(selectedIndex, "show me this");
  }, [location, selectedIndex]);

  console.log(location, "show me");

  return (
    <MenuList className={classes.menuList}>
      <MenuItem
        classes={{ root: classes.root, selected: classes.selected }}
        selected={selectedIndex === `/dashboard/myvideos` ? true : false}
      >
        <ListItem
          component={Link}
          to={`${match.url}/myvideos`}
          className={classes.menuListItem}
        >
          <ListItemIcon className={classes.menuListItemContainer}>
            <OndemandVideoOutlinedIcon color={"secondary"} />
          </ListItemIcon>
          <ListItemText
            primary="My Videos"
            primaryTypographyProps={{ style: listItemTextStyles }}
          />
        </ListItem>
      </MenuItem>
      <MenuItem
        classes={{ root: classes.root, selected: classes.selected }}
        selected={selectedIndex === `/dashboard/mylessons` ? true : false}
      >
        <ListItem
          component={Link}
          to={`${match.url}/mylessons`}
          className={classes.menuListItem}
        >
          <ListItemIcon className={classes.menuListItemContainer}>
            <ComputerOutlinedIcon color={"secondary"} />
          </ListItemIcon>
          <ListItemText
            primary="My Lessons"
            primaryTypographyProps={{ style: listItemTextStyles }}
          />
        </ListItem>
      </MenuItem>
      <MenuItem
        classes={{ root: classes.root, selected: classes.selected }}
        selected={selectedIndex === `/dashboard/myblog` ? true : false}
      >
        <ListItem
          component={Link}
          to={`${match.url}/myblog`}
          className={classes.menuListItem}
        >
          <ListItemIcon className={classes.menuListItemContainer}>
            <ChromeReaderModeOutlinedIcon color={"secondary"} />
          </ListItemIcon>
          <ListItemText
            primary="My Blog"
            primaryTypographyProps={{ style: listItemTextStyles }}
          />
        </ListItem>
      </MenuItem>
      <MenuItem
        classes={{ root: classes.root, selected: classes.selected }}
        selected={selectedIndex === `/dashboard/myprofile` ? true : false}
      >
        <ListItem
          component={Link}
          to={`${match.url}/myprofile`}
          className={classes.menuListItem}
        >
          <ListItemIcon className={classes.menuListItemContainer}>
            <AccountCircleOutlinedIcon color={"secondary"} />
          </ListItemIcon>
          <ListItemText
            primary="My Profile"
            primaryTypographyProps={{ style: listItemTextStyles }}
          />
        </ListItem>
      </MenuItem>
      <MenuItem
        classes={{ root: classes.root, selected: classes.selected }}
        selected={selectedIndex === `/dashboard/bugs` ? true : false}
      >
        <ListItem
          component={Link}
          to={`${match.url}/bugs`}
          className={classes.menuListItem}
        >
          <ListItemIcon className={classes.menuListItemContainer}>
            <BugReportOutlinedIcon color={"secondary"} />
          </ListItemIcon>
          <ListItemText
            primary="Bugs"
            primaryTypographyProps={{ style: listItemTextStyles }}
          />
        </ListItem>
      </MenuItem>
    </MenuList>
  );
}
