export default function validateForm(values) {
  let errors = {};
  // Username Validation
  if (values.username && !values.username) {
    errors.username = "Username required.";
  } else if (values.username.length < 3) {
    errors.username = "Length must be at least 3 characters.";
  }

  // Email Validation
  if (values.email && !values.email) {
    errors.email = "Email required.";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid Email Address.";
  }
  // Password Validation
  if (values.password && !values.password) {
    errors.password = "Password Required.";
  } else if (values.password.length < 6) {
    errors.password = "Length must be at least 6 characters.";
  }

  return errors;
}
