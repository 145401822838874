import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route } from "react-router-dom";
import DashboardMenuMobile from "./DashboardMenuMobile";
import styled from "styled-components";

// Dashboard Pages
import Bugs from "./DashboardPages/Bugs";

const DashboardMobileGrid = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 55px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: theme.palette.custom.highlightColor,
    padding: "1rem",
  },
}));

export default function Dashboard({ match }) {
  const classes = useStyles();

  return (
    <DashboardMobileGrid>
      <div className={classes.root}>
        <Switch>
          <Route
            path={`${match.path}`}
            exact
            component={() => <h1>Dashboard</h1>}
          />
          <Route
            path={`${match.path}/myvideos`}
            exact
            component={() => <h1>videos</h1>}
          />
          <Route
            path={`${match.path}/mylessons`}
            exact
            component={() => <h1>lessons</h1>}
          />
          <Route
            path={`${match.path}/myblog`}
            exact
            component={() => <h1>blog</h1>}
          />
          <Route
            path={`${match.path}/myprofile`}
            exact
            component={() => <h1>profile</h1>}
          />
          <Route path={`${match.path}/bugs`} exact component={Bugs} />
        </Switch>
      </div>
      <DashboardMenuMobile match={match} />
    </DashboardMobileGrid>
  );
}
