import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  category: {
    margin: 0,
    padding: '4px 25px',
    verticalAlign: 'top',
    letterSpacing: '0.5px',
    border: 'solid 2px white',
    fontFamily: '"Montserrat",sans-serif',
    borderRadius: '25px',
    borderTopRightRadius: '0px',
    fontWeight: 600,
  },
}));
