import styled from 'styled-components'

export const LinkViewStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    gap: 1rem;  

    background: #eff2f4;
    padding: 0.5rem 1rem;
    border-radius: 4px;

    a {
        text-decoration: none;
        color: #999898;
        font-size: 1rem;
        transition: color 0.25s ease-in-out;

        &:hover {
            color: #d64855;
        }
    }

    h2 {
        color: #1d1a1a;
        transition: color 0.25s ease-in-out;
        text-decoration: underline;

        &:hover {
            color: #d64855;
        }
    }

    p {
        font-size: 1.2rem;
        margin: 0;
        color: #332d2d;
    }
`;
