import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Dashboard from "./Dashboard";
import DashboardMobile from "./DashboardMobile";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    height: "100%",
    width: "100%",
  },
  container: { width: "100%" },
});

export default function RenderDashboard({ match }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Hidden className={classes.root} mdDown implementation="css">
        <Dashboard match={match} />
      </Hidden>
      <Hidden className={classes.root} lgUp implementation="css">
        <DashboardMobile match={match} />
      </Hidden>
    </div>
  );
}
