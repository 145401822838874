import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFetch } from "../hooks";
import RenderChildList from "../components/RenderChildList/RenderChildList";
import VideoEmbedYouTube from "../components/VideoEmbedYouTube";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100%",
    padding: "1rem",
    background: theme.palette.custom.highlightColor,
  },
}));

// const gridTestStyle = {
//   display: "flex",
//   flexDirection: "row",
//   flexWrap: "wrap",
//   justifyContent: "space-between",
// }

// const GridLayout = ({items}) => <div style={gridTestStyle}>{items}</div>

const Videos = () => {
  const classes = useStyles();
  const url = `http://localhost:1337/videos`;

  const {  status, error, data } = useFetch(url);

  console.log(data)

  if (error) return <p>Ops. Something went wrong.</p>;
  if (status !== "fetched") return <p>data is loading</p>;
  if (data === null) return <p>still loading</p>;

  return (
    <div className={classes.root}>
      <RenderChildList data={data} >
        <VideoEmbedYouTube />
      </RenderChildList>
    </div>
  );
};

export default Videos;
