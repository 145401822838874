import * as actionTypes from "../actions/types";

const INITIAL_STATE = {
  currentUser: null,
};

export const setUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_USER: {
      const token = action.payload.userToken;
      const user = action.payload.currentUser;

      return {
        currentUser: user,
        userToken: token,
      };
    }

    case actionTypes.LOGOUT_USER: {
      const user = action.payload.currentUser;
      const history = action.payload.history;
      sessionStorage.removeItem("user");
      history.push("/");
      return {
        currentUser: user,
      };
    }

    default:
      return state;
  }
};
